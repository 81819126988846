const game = {
    101: "百家乐",
    102: "射龙门",
    103: "火蟾蜍",
    104: "龙虎",
    105: "炸金花",
    106: "百人牛牛",
    107: "二八杠",
    108: "森林大转轮",
    109: "奔驰宝马",
    110: "骰宝",
    111: "左轮百家",
    112: "骰子百家",
    113: "推筒子",
    114: "鱼虾蟹",
    115: "爬梯子",
    116: "眯牌百家",
    117: "移牌百家(多管)",
    118: "移牌百家(单管)",
    119: "五星宏辉",
    120: "红黑大战",
    121: "色碟",
    122: "番摊",
    123: "视讯百家",
    124: "印度炸金花",
    125: "免佣安达巴哈",
    126: "安达巴哈",
    301: "对战炸金花",
    302: "抢庄牛牛",
    303: "极速炸金花",
    304: "单挑牛牛",
    305: "通比牛牛",
    306: "抢庄二八杠",
    307: "抢庄推筒子",
    308: "三公",
    309: "牌九",
    310: "抢庄炸金花",
    311: "越南扑克",
    312: "博登",
    313: "21点",
    314: "对战猜大小",
    501: "龙王捕鱼",
    502: "海王捕鱼",
    10001: "火凤凰",
    10002: "埃及艳后",
    10003: "百变熊猫",
    10004: "赢多多",
    10005: "齐天大圣",
    10006: "五神兽",
    10007: "三倍猴子",
    10008: "多彩多福",
    10009: "夜上海",
    10010: "众神",
    10012: "海洋派对",
    10013: "后羿射日",
    10014: "麻將來了",
    10015: "蔬果精灵",
    10016: "新火凤凰",
    10017: "魔龙传奇",
    10018: "山海志异",
    10019: "野蛮世界",
    10020: "王者之剑",
    10021: "聚宝盆",
    10022: "埃及之眼",
    10023: "海盗宝藏",
    10024: "雷神",
    10025: "阿拉丁神灯",
    10026: "战神吕布",
    10027: "麻將來了2",
    10028: "忍者武士",
    10029: "七龙珠",
    10030: "海贼王",
    10031: '大富翁',
    10032: '海贼王2',
    10033: '战神赛特',
    10034: '神龙传奇',
    10035: '强棒',
    10036: '养鸡场',
    10037: '石中剑',
    10039: '大老爷',
    20002: "五福临门",
};

const bacc = {
    1: "闲",
    2: "庄",
    3: "和",
    4: "闲对",
    5: "庄对",
}

let diceRoadType = {
    0: "预设",
    1: "大",
    2: "小",
    3: "豹子",
};

let climbLadder = {
    1: "开始左边",
    2: "开始右边",
    3: "终点左边",
    4: "终点右边",
    5: "梯子数量3",
    6: "梯子数量4",
    7: "终点左边且梯子数量3",
    8: "终点右边且梯子数量3",
    9: "终点左边且梯子数量4",
    10: "终点右边且梯子数量4"
};

export default {
    ...game,
    init: "取得游戏设定",
    switchType: {
        0: "预设",
        1: "带牌",
        2: "杀数",
    },
    gameModule: {
        title: "游戏类型",
        0: "没有下注",
        1: "百人棋牌",
        2: "对战棋牌",
        3: "街机-老虎机",
        4: "街机-捕鱼机",
    },
    title: "游戏",
    win: "赢",
    lose: "输",
    pay: "赔",
    betArea: "下注区",
    areaName: "押注区",
    moveCard: "移牌区",
    backCard: "备牌区",
    orderOfOpeningCard: "开牌顺序",
    licensingOrder: "发牌顺序",
    state: {
        title: "游戏状态",
        sleep: "游戏结束",
        notEnd: "游戏尚未结束"
    },
    banker: "庄",
    player: "闲",
    tie: "和",
    rightStart: "右边开始",
    rightEnd: "右边结束",
    leftStart: "左边开始",
    leftEnd: "左边结束",
    numberOfLadders: "{n}个梯子",
    seat: "座位",
    area: {
        //百家乐
        101: bacc,
        //射龙门
        102: {
            1: "撞柱",
            2: "射中龙门",
            3: "没中",
            4: "红",
            5: "黑",
            6: "大",
            7: "小",
            8: "单",
            9: "双",
        },
        //火蟾蜍
        103: climbLadder,
        //龙虎
        104: {
            1: "龙",
            2: "虎",
            3: "和",
        },
        //炸金花
        105: {
            1: "庄",
            2: "天",
            3: "地",
            4: "玄",
            5: "黄",
            winType: {
                1: "豹子",
                2: "同花顺",
                3: "金花",
                4: "顺子",
                5: "对子",
                6: "高牌",
                7: "地龙",
            },
            isLose: "庄家通杀"
        },
        //百人牛牛
        106: {
            1: "庄",
            2: "天",
            3: "地",
            4: "玄",
            5: "黄",
            winType: {
                0: "五小牛",
                1: "四炸",
                2: "五花牛",
                3: "四花牛",
                4: "牛牛",
                5: "牛九",
                6: "牛八",
                7: "牛七",
                8: "牛六",
                9: "牛五",
                10: "牛四",
                11: "牛三",
                12: "牛二",
                13: "牛一",
                14: "无牛",
            },
            rateRule: {
                1: "没牛到牛六",
                2: "牛七到牛九",
                3: "牛牛到五小牛",
                4: "抽水",
            },
            isLose: "庄家通杀"
        },
        //二八杠
        107: {
            2: "上门-赢",
            3: "中门-赢",
            4: "下门-赢",
            5: "上门-输",
            6: "中门-输",
            7: "下门-输",
            rateRule: {
                1: "点数",
                2: "对子",
                3: "白板",
                4: "赢抽水",
                5: "输抽水",
            },
            winType: {
                1: "鳖十",
                2: "{n}点",
                3: "二八杠",
                4: "对子",
            },
            block: {
                1: "庄家",
                2: "上门",
                3: "中门",
                4: "下门",
            },
        },
        //森林大转轮
        108: {
            1: "狮子红",
            2: "狮子绿",
            3: "狮子黄",
            4: "熊猫红",
            5: "熊猫绿",
            6: "熊猫黄",
            7: "猴子红",
            8: "猴子绿",
            9: "猴子黄",
            10: "兔子红",
            11: "兔子绿",
            12: "兔子黄",
            nowSpecialRule: {
                1: "大三元",
                2: "大四喜",
                3: "送灯",
                4: "霹雳闪电",
            }
        },
        //奔驰宝马
        109: {
            1: "法拉利",
            2: "蓝宝坚尼",
            3: "玛莎拉蒂",
            4: "保时捷",
            5: "Lexus",
            6: "福斯大众",
            7: "奔驰宾士",
            8: "宝马BMW",
        },
        //骰宝
        110: {
            1: "豹子1",
            2: "豹子2",
            3: "豹子3",
            4: "豹子4",
            5: "豹子5",
            6: "豹子6",
            7: "任意豹子",
            8: "总数4",
            9: "总数5",
            10: "总数6",
            11: "总数7",
            12: "总数8",
            13: "总数9",
            14: "总数10",
            15: "总数11",
            16: "总数12",
            17: "总数13",
            18: "总数14",
            19: "总数15",
            20: "总数16",
            21: "总数17",
            22: "骰子1",
            23: "骰子2",
            24: "骰子3",
            25: "骰子4",
            26: "骰子5",
            27: "骰子6",
            28: "大",
            29: "小",
            30: "单",
            31: "双",
            roadType: diceRoadType
        },
        //左轮百家
        111: bacc,
        //骰子百家
        112: bacc,
        //推筒子
        113: {
            2: "出-赢",
            3: "川-赢",
            4: "尾-赢",
            5: "出-输",
            6: "川-输",
            7: "尾-输",
            rateRule: {
                1: "点数",
                2: "对子",
                3: "白板",
                4: "赢抽水",
                5: "输抽水",
            },
            block: {
                1: "庄",
                2: "出",
                3: "川",
                4: "尾",
            },
            winType: {
                1: "鳖十",
                2: "{n}点",
                3: "对子",
            },
        },
        //鱼虾蟹
        114: {
            1: "1点(鱼)-围骰",
            2: "2点(虾)-围骰",
            3: "3点(葫卢)-围骰",
            4: "4点(铜钱)-围骰",
            5: "5点(螃蟹)-围骰",
            6: "6点(鸡)-围骰",
            7: "全围",
            8: "指定三色-红",
            9: "指定三色-绿",
            10: "指定三色-蓝",
            11: "任意三色",
            12: "指定双色-红",
            13: "指定双色-绿",
            14: "指定双色-蓝",
            15: "指定单色-红",
            16: "指定单色-绿",
            17: "指定单色-蓝",
            18: "总点-4点",
            19: "总点-5点",
            20: "总点-6点",
            21: "总点-7点",
            22: "总点-8点",
            23: "总点-9点",
            24: "总点-10点",
            25: "总点-11点",
            26: "总点-12点",
            27: "总点-13点",
            28: "总点-14点",
            29: "总点-15点",
            30: "总点-16点",
            31: "总点-17点",
            32: "三军-1点",
            33: "三军-2点",
            34: "三军-3点",
            35: "三军-4点",
            36: "三军-5点",
            37: "三军-6点",
            38: "大",
            39: "小",
            40: "单",
            41: "双",
            roadType: diceRoadType
        },
        //爬梯子
        115: climbLadder,
        //眯牌百家
        116: bacc,
        //移牌百家(多管)
        117: bacc,
        //移牌百家(单管)
        118: bacc,
        119: {
            1: "黑桃",
            2: "爱心",
            3: "方块",
            4: "梅花",
            5: "Joker(皇冠)",
        },
        120: {
            1: "红方",
            2: "黑方",
            3: "幸运一击",
            winType: {
                1: "豹子",
                2: "同花顺",
                3: "同花",
                4: "顺子",
                5: "对子",
                6: "高牌",
            },
            rateRule: {
                1: "红方",
                2: "黑方",
                3: "对子9以上",
                4: "顺子",
                5: "金花",
                6: "顺金",
                7: "豹子",
            }
        },
        121: {
            1: "双",
            2: "单",
            3: "一白",
            4: "一红",
            5: "全白",
            6: "全红",
            red: "红",
            white: "白",
            rateRule: {
                1: "双",
                2: "单",
                3: "一白",
                4: "一红",
                5: "全白",
                6: "全红",
                7: "单/双Bonus",
                8: "一白/一红Bonus",
                9: "全白/全红Bonus",
            }
        },
        122: {
            1: "<i class='icon-tiny fan-tan fan-tan-1'></i> ",
            2: "<i class='icon-tiny fan-tan fan-tan-2'></i> ",
            3: "<i class='icon-tiny fan-tan fan-tan-3'></i> ",
            4: "<i class='icon-tiny fan-tan fan-tan-4'></i> ",
            5: "<i class='icon-tiny fan-tan fan-tan-5'></i> ",
            6: "<i class='icon-tiny fan-tan fan-tan-6'></i> ",
            7: "<i class='icon-tiny fan-tan fan-tan-7'></i> ",
            8: "<i class='icon-tiny fan-tan fan-tan-8'></i> ",
            9: "<i class='icon-tiny fan-tan fan-tan-9'></i> ",
            10: "<i class='icon-tiny fan-tan fan-tan-10'></i> ",
            11: "<i class='icon-tiny fan-tan fan-tan-11'></i> ",
            12: "<i class='icon-tiny fan-tan fan-tan-12'></i> ",
            13: "<i class='icon-tiny fan-tan fan-tan-13'></i> ",
            14: "<i class='icon-tiny fan-tan fan-tan-14'></i> ",
            str: {
                1: "[1]",
                2: "[2]",
                3: "[3]",
                4: "[4]",
                5: "[1、2]",
                6: "[3、1]",
                7: "[4、1]",
                8: "[2、3]",
                9: "[2、4]",
                10: "[3、4]",
                11: "[1、2、3]",
                12: "[1、2、4]",
                13: "[1、3、4]",
                14: "[2、3、4]",
            }
        },
        //視訊百家
        123: bacc,
        124: {
            1: "玩家A",
            2: "玩家B",
            3: "和",
            4: "玩家A对子+",
            5: "玩家B对子+",
            6: "六张牌奖励",
            winType: {
                1: "六张-同花顺AKQJ10",
                2: "六张-同花顺",
                3: "六张-铁支",
                4: "六张-葫芦",
                5: "六张-同花",
                6: "六张-顺子",
                7: "六张-三条",
                8: "六张-高牌",
                9: "三张-三条AAA",
                10: "三张-三条",
                11: "三张-同花顺",
                12: "三张-顺子",
                13: "三张-同花",
                14: "三张-对子",
                15: "三张-高牌"
            },
            rateRule: {
                1: "玩家A加注",
                2: "玩家B加注",
                3: "和",
                4: "三张-对子",
                5: "三张-同花",
                6: "三张-顺子",
                7: "三张-同花顺",
                8: "三张-三条",
                9: "三张-三条AAA",
                10: "六张-三条",
                11: "六张-顺子",
                12: "六张-同花",
                13: "六张-葫芦",
                14: "六张-铁支",
                15: "六张-同花顺",
                16: "六张-同花顺AKQJ10",
            }
        },
        125: {
            1: "安达",
            2: "巴哈",
            3: "第一手获胜"
        },
        126: {
            1: "安达",
            2: "巴哈",
            3: "第一手获胜"
        },
    },
    mj: {
        1: "白板",
        2: "一筒",
        3: "二筒",
        4: "三筒",
        5: "四筒",
        6: "五筒",
        7: "六筒",
        8: "七筒",
        9: "八筒",
        A: "九筒",
    },
    "20002Content": {
        first: "第一重 五福临门",
        second: "第二重 集字乐",
        third: "第三重 幸运奖",
        1: "第一门",
        2: "第二门",
        3: "第三门",
        4: "第四门",
        5: "第五门",
        collect: "目前累计"
    },
    "10019Content": {
        centerReel: "中间滚轮",
        mari: "小玛莉"
    }
}
