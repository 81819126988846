<template>
    <b-table
             v-if="gameData"
             striped
             hover
             responsive="xl"
             :items="gameData"
             :fields="getFields()">

        <template v-slot:cell(slotGUI)="data">
            <template v-if='data.item.game_state !== "LuckySkyGame"'>
                <div style='white-space:nowrap;' v-for='(xItem, x) in getPositionResult(data.index)' :key='x'>
                    <i v-for='(id, y) in xItem' :class='getSymbolIcon(data.index, id, x, y)' :key='y'></i>
                </div>
            </template>

            <div class='mt-2'>
                <div v-if='data.item.game_state === "LuckySkyGame"'>
                    <h3 class='text-primary'>{{ $t('slot.10017.box') }}</h3>
                </div>
                {{ $t('totalWin') }} : {{ convertMoney(currencyType, data.item.win_point, true) }}
            </div>
        </template>

        <template v-slot:cell(detail)="data">
            <p style="white-space:nowrap;" v-html="getSpinTitle(data.item, data.index)">
            </p>
            <p style="white-space:nowrap;">
                {{ $t('totalWin') }} : {{ convertMoney(currencyType, data.item.win_point, true) }}
            </p>
            <p style="white-space:nowrap;">
                {{ $t('slot.winLineCount') }} : {{ getWinLineCount(data.index) }}
            </p>

            <p style="white-space:nowrap;">
                {{ $t('slot.multiplier') }} :{{ data.item.multiplier }}
            </p>

            <p style='white-space:nowrap;'
               v-if='hasOtherSummaryHtmlStr()'
               v-html='getOtherSummaryHtmlStr(data.index)'>
            </p>

            <b-row class="m-0 p-0 border-top border-dark" v-if="getWinLineCount(data.index) > 0"
                   v-html="getOtherHtmlStr(data.index)">
            </b-row>

        </template>

    </b-table>
</template>

<script>
import { checkSymbolId } from "@/utils/Slot";
import { LUCKY_SKY_GAME_LIST } from '@/utils/enum';

export default {
    name: "SlotNormalTable",
    props: {
        item: { bet_point: 0 },
        gameData: null,
        gameType: { type: Number, default: null, required: true },
        currencyType: { type: String, default: null, required: true }
    },
    data() {
        return {
            LUCKY_SKY_GAME_LIST,
            frameId: null,
            beforeTimestamp: null,
            winLineIndex: 0,
            winLineBoxShow: true,
            winLineBoxCount: 0
        }
    },
    mounted() {
        if (this.item.win_point) {
            this.startWinBoxAnimation();
        }
    },
    methods: {
        getFields() {
            return [
                {
                    key: 'slotGUI',
                    label: '',
                    sortable: false
                },
                {
                    key: 'detail',
                    label: this.$t('detail'),
                    tdClass: 'align-top',
                    sortable: false
                },
            ];
        },
        getPositionResult(index) {
            let positionResult = this.gameData[index].position_result;
            let symbolList = [];

            let length = positionResult.length;
            for (let i = 0; i < length; i++) {
                let length1 = positionResult[i].length;
                for (let j = 0; j < length1; j++) {
                    symbolList[j] = symbolList[j] || [];
                    symbolList[j][i] = positionResult[i][j];
                }
            }
            return symbolList;
        },
        getSymbolIcon(index, id, x, y) {
            let symbolId = Number(id);
            let item = this.gameData[index];
            let prefix = ""
            let winLines = item.win_lines || [];
            if (winLines.length > 0) {
                let winLine = winLines[this.winLineIndex % item.win_lines.length];
                symbolId = checkSymbolId(this.gameType, item, index, winLine, symbolId, x, y);
                let winPosition = winLine.is_win_position;
                if (winPosition !== null && winPosition[y][x]) {
                    prefix = this.winLineBoxShow ? "winLineBox" : "";
                }
            } else {
                symbolId = checkSymbolId(this.gameType, item, index, null, symbolId, x, y);
            }

            return `icon-small slot-${this.gameType} slot-${this.gameType}-${symbolId}  ${prefix}`;
        },
        getWinLineCount(index) {
            let item = this.gameData[index];
            let winLines = item.win_lines || [];
            return winLines.length;
        },
        getOtherHtmlStr(index) {
            let html = "";

            let item = this.gameData[index];
            let winLines = item.win_lines || [];

            let length = winLines.length;
            let winLineIndex = this.winLineIndex % length;
            for (let i = 0; i < length; i++) {
                let winLine = winLines[i];

                let point = winLine.win_point;
                point = this.convertMoney(this.currencyType, point, true);

                let prefix = "";
                if (winLineIndex === i) {
                    prefix = "winLineBox";
                }

                let lineGameType = [10001, 10002, 10005, 10009, 10013, 10015, 10016, 10020, 10022, 10023, 10025, 10028, 10037];

                if (lineGameType.indexOf(this.gameType) > -1) {
                    if (winLine.win_point > 0) {
                        html += `<div class="col col-xl-3 col-lg-4 col-md-7 col-sm-7 mb-2 mt-2">`
                        html += `<i class="row mb-1 icon-big slot-line-${this.gameType} slot-line-${this.gameType}-${winLine.line_no + 1} ${prefix}"></i>`
                        if (winLine.line_no > -1) {
                            html += `<span class="row text-info" style="white-space:nowrap;">LinNo : ${winLine.line_no + 1}</span>`;
                        }

                        //海盜寶藏 喜從天降
                        if (winLine.win_type === 2 && LUCKY_SKY_GAME_LIST.includes(this.gameType)) {
                            html += `<span class='row text-info'>${this.$t('slot.10017.box')}</span>`;
                        }

                        html += `<span class="row" style="white-space:nowrap;">${point}</span>`;
                        html += "</div>";
                    }
                } else {
                    if (prefix.length > 0) {
                        prefix = "border-danger border"
                    }

                    html += `<span class="mr-2 mt-1 mb-1 p-2 ${prefix}">${i + 1} : ${point}</span>`
                }
            }

            return html;
        },
        hasOtherSummaryHtmlStr() {
            return [10037].includes(this.gameType);
        },
        getOtherSummaryHtmlStr(index) {
            if ([10037].includes(this.gameType)) {
                let item = this.gameData[index];
                let htmlStr = ``;
                if (item.game_state === 'FreeGame') {
                    htmlStr += `<p style='white-space:nowrap;'>${this.$t('slot.10037.freeGameRound')} : ${item.free_spin_times} / ${item.free_spin_total_times}</p>`;
                }

                if (item.next_is_sword_line_respin) {
                    htmlStr += `<p style='white-space:nowrap;'>${this.$t('slot.10037.triggerExcalibur')} : ${item.next_is_sword_line_respin ? this.$t('pk.yes') : this.$t('pk.no')}</p>`;
                    htmlStr += `<p style='white-space:nowrap;'>${this.$t('slot.10037.accEnergyPower')} : ${item.acc_energy_power}</p>`;
                    switch (item.acc_energy_power) {
                        case 2:
                            htmlStr += `<p style='white-space:nowrap;'>${this.$t('slot.10037.nextWillTurn')} : ${this.$t('slot.10037.apprentice')} > ${this.$t('slot.10037.apprentice')}（Wild）</p>`;
                            break;
                        case 4:
                            htmlStr += `<p style='white-space:nowrap;'>${this.$t('slot.10037.nextWillTurn')} : ${this.$t('slot.10037.merlin')} > ${this.$t('slot.10037.merlin')}（Wild）</p>`;
                            break;
                        case 6:
                            htmlStr += `<p style='white-space:nowrap;'>${this.$t('slot.10037.nextWillTurn')} : ${this.$t('slot.10037.arthur')} > ${this.$t('slot.10037.arthur')}（Wild）</p>`;
                            break;
                    }
                }
                return htmlStr;
            }
            return '';
        },
        getSpinTitle(item, index) {
            let title = "[ " + item.game_state;
            if (index > 0) {
                title += "<span class='text-success ml-1'>" + index + "</span>";
            }
            title += " ]"
            if ([10022, 10028].includes(this.gameType) && item.expand_symbol > 0) {
                title += `<i class="icon-super-tiny slot-${this.gameType} slot-${this.gameType}-${item.expand_symbol}"></i>`
                // title += `<i class="icon-super-tiny slot-10022 slot-10022-${item.expand_symbol}"></i>`
            }
            return title;
        },
        updateFrame(timestamp) {
            if (!this.beforeTimestamp) {
                this.beforeTimestamp = timestamp;
            }

            let dt = timestamp - this.beforeTimestamp;

            if (dt > 300) {
                this.beforeTimestamp = timestamp;
                this.winLineBoxShow = !this.winLineBoxShow;
                this.winLineBoxCount++;
            }

            if (this.winLineBoxCount === 6) {
                this.winLineBoxCount = 0;
                this.winLineIndex++;
            }

            this.frameId = window.requestAnimationFrame(this.updateFrame);
        },
        startWinBoxAnimation() {
            this.frameId = window.requestAnimationFrame(this.updateFrame);
        },
        reset() {
            this.frameId = null;
            this.winLineIndex = 0;
            this.beforeTimestamp = null;
            this.winLineBoxShow = true;
            this.winLineBoxCount = 0;
        },
        close() {
            this.frameId && window.cancelAnimationFrame(this.frameId);
            this.frameId = null;
        }
    },
    destroyed() {
        this.close();
    }
}
</script>

<style scoped></style>
