<template>
  <b-table
           v-if="gameData"
           striped
           hover
           responsive="xl"
           :items="gameData"
           :fields="getFields()">

    <template v-slot:cell(slotGUI)="data">
      <div
           class="fade-in"
           v-for="(winInfo, index) in gameData[data.index].win_info_list"
           v-if="gameData[data.index].page === index + 1">
        <div
             v-if="winInfo.reel_position"
             style="white-space:nowrap;"
             v-for="(xItem, x) in getPositionResult(data.index)"
             :key="x">
          <i v-for="(id, y) in xItem"
             :class="getSymbolIcon(winInfo, x, y)"
             :key="y">
          </i>
        </div>


        <div class="mt-2">
          <div v-if="LUCKY_SKY_GAME_LIST.includes(gameType) && !winInfo.reel_position">
            <h3 class="text-primary">{{ $t('slot.10017.box') }}</h3>
          </div>
          {{ $t('totalWin') }} : {{ convertMoney(currencyType, getWinLinesTotalWin(winInfo), true) }}
          <div v-if="isShowCascadeMultiply() && getWinLinesCascadeMultiply(winInfo)">
            {{ $t('cascadeMultiply') }} : {{ getWinLinesCascadeMultiply(winInfo) }}
          </div>
        </div>
      </div>

      <b-pagination
                    v-if="gameData[data.index].win_info_list.length > 1"
                    class="page mt-2"
                    size="md"
                    :total-rows="gameData[data.index].win_info_list.length"
                    v-model="gameData[data.index].page"
                    per-page="1"
                    first-number
                    last-number></b-pagination>
    </template>


    <template v-slot:cell(detail)="data">
      <p style="white-space:nowrap;" v-html="getSpinTitle(data.item, data.index)">

      </p>
      <p style="white-space:nowrap;">
        {{ $t('totalWin') }} : {{ convertMoney(currencyType, data.item.win_point, true) }}
      </p>

      <b-row class="m-0 p-0 border-top border-dark"
             v-if="isShowLine()"
             v-html="showLine(gameData[data.index])">

      </b-row>

    </template>


  </b-table>
</template>

<script>

import { checkExplodeSymbolId } from "@/utils/Slot";
import { LUCKY_SKY_GAME_LIST } from '@/utils/enum';

export default {
  name: "SlotExplodeTable",
  props: {
    item: { bet_point: 0 },
    gameData: null,
    gameType: { type: Number, default: null, required: true },
    currencyType: { type: String, default: null, required: true }
  },
  data() {
    return {
      LUCKY_SKY_GAME_LIST,
    }
  },
  methods: {
    isShowLine() {
      return [10017, 10018, 10034, 10039].includes(this.gameType);
    },
    isShowCascadeMultiply() {
      return [10014, 10027, 10030].includes(this.gameType);
    },
    getFields() {
      return [
        {
          key: 'slotGUI',
          label: '',
          sortable: false
        },
        {
          key: 'detail',
          label: this.$t('detail'),
          tdClass: 'align-top',
          sortable: false
        },
      ];
    },
    getSpinTitle(item, index) {
      let title = "[ " + item.game_state;
      if (index > 0) {
        title += "<span class='text-success ml-1'>" + index + "</span>";

      }
      return title + " ]";
    },
    getPositionResult(index) {
      let positionResult = this.gameData[index].position_result;
      let symbolList = [];

      let length = positionResult.length;
      for (let i = 0; i < length; i++) {
        let length1 = positionResult[i].length;
        for (let j = 0; j < length1; j++) {
          symbolList[j] = symbolList[j] || [];
          symbolList[j][i] = positionResult[i][j];
        }
      }
      return symbolList;
    },
    getSymbolIcon(winInfo, x, y) {
      let symbolId = winInfo.origin_frame[y][x];
      let prefix = ""
      symbolId = checkExplodeSymbolId(this.gameType, symbolId, winInfo, x, y);

      let winLines = winInfo.win_lines || [];
      let isWin = winLines.some(winLine => winLine.is_win_position[y][x]);
      if (isWin) {
        prefix = "alerts-border";
      }

      return `icon-small slot-${this.gameType} slot-${this.gameType}-${symbolId}  ${prefix}`;
    },
    getWinLinesTotalWin(winInfo) {
      let winLines = winInfo.win_lines || [];
      if (winLines.length > 0) {
        return winInfo.win_lines
          .map(winLine => winLine.win_point)
          .reduce((a, b) => a + b)
      } else {
        return 0;
      }
    },
    getWinLinesCascadeMultiply(winInfo) {
      let winLines = winInfo.win_lines || [];
      if (winLines.length > 0) {
        return winInfo.win_lines[0].cascade_multiply || 0;
      } else {
        return 0;
      }
    },
    showLine(data) {
      let winInfo = data.win_info_list[data.page - 1];

      let htmlStr = "";

      if (winInfo.win_lines) {
        let length = winInfo.win_lines.length;
        for (let i = 0; i < length; i++) {
          let winLine = winInfo.win_lines[i];
          if (winLine.win_point > 0) {
            htmlStr += `<div class="col col-xl-3 col-lg-4 col-md-7 col-sm-7 mb-2 mt-2">`
            htmlStr += `<i class="row mb-1 icon-big slot-line-${this.gameType} slot-line-${this.gameType}-${winLine.line_no + 1}"></i>`
            if (winLine.line_no > -1) {
              htmlStr += `<span class="row text-info" style="white-space:nowrap;">LinNo : ${winLine.line_no + 1}</span>`;
            }
            let point = this.convertMoney(this.currencyType, winLine.win_point, true);
            htmlStr += `<span class="row" style="white-space:nowrap;">${point}</span>`;
            htmlStr += "</div>";
          }
        }
      }

      return htmlStr;
    }
  }
}
</script>

<style scoped></style>
