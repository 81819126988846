<template>
    <b-table
        v-if='gameData'
        striped
        hover
        responsive='xl'
        :items='gameData'
        :fields='getFields()'
    >

        <template v-slot:cell(slotGUI)='data'>
            <div v-if='isSlotResult(gameData[data.index])'>
                <div
                    class='fade-in'
                    v-if='gameData[data.index].page === index + 1'
                    v-for='(winInfo, index) in gameData[data.index].win_info_list'
                >
                    <div
                        v-if='winInfo.reel_position'
                        style='white-space:nowrap;'
                        v-for='(xItem, x) in getPositionResult(data.index)'
                        :key='x'
                    >
                        <i
                            v-for='(id, y) in xItem'
                            :class='getSymbolIcon(winInfo, x, y)'
                            :key='y'
                        >
                        </i>
                    </div>

                    <div class='mt-2'>
                        {{ $t('totalWin') }} : {{ convertMoney(currencyType, getWinLinesTotalWin(winInfo), true) }}
                    </div>
                </div>

                <b-pagination
                    v-if='gameData[data.index].win_info_list.length > 1'
                    class='page mt-2'
                    size='md'
                    :total-rows='gameData[data.index].win_info_list.length'
                    v-model='gameData[data.index].page'
                    per-page='1'
                    first-number
                    last-number
                ></b-pagination>
            </div>
            <div v-else>
                <div v-if='isDiceResult(gameData[data.index])'>
                    <p style='white-space:nowrap;'>
                        {{ $t('slot.10031.currentRound') }} : {{ getMonopolyCurrentRound(data) }}
                    </p>
                    <p style='white-space:nowrap;'>
                        <!--						{{ $t('totalWin') }} : {{ convertMoney(currencyType, data.item.win_point, true) }}-->
                        {{ $t('slot.10031.mapSymbol') }} : {{ getMonopolyAwardsTitle(data) }}
                    </p>
                    <p style='white-space:nowrap;'>
                        {{ $t('slot.10031.dice') + $t('slot.point') }} :
                    </p>
                    <i
                        v-for='n in getDiceCount()'
                        :class='getMonopolyDicePoint(data, n)'
                        :key='n'
                    />
                    <div class='map'>
                        <div
                            v-for='(row, i) in viewRowList'
                            :key='i'
                        >
                            <div
                                v-for='(mapSymbol, j) in row'
                                :key='j'
                                :class='mapSymbolClass(data, mapSymbol)'
                                :id='bindMapID(mapSymbol)'
                            >
                                {{ showMapSymbol(mapSymbol) }}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </template>

        <template v-slot:cell(detail)='data'>
            <p
                style='white-space:nowrap;'
                v-html='getSpinTitle(data.item, data.index)'
            >

            </p>
            <!--   贏分   -->
            <p style='white-space:nowrap;'>
                {{ $t('totalWin') }} : {{ convertMoney(currencyType, data.item.win_point, true) }}
            </p>
            <!--   取得倍數   -->
            <p
                v-if='gameData[data.index].now_acc_game_multiply > 0 &&
				data.item.game_state === "BaseGame"'
                style='white-space:nowrap;'
            >
                {{ $t('baseGameTotalMultiplier') }} : {{ gameData[data.index].now_acc_game_multiply }}
            </p>
            <p
                v-if='gameData[data.index].now_acc_game_multiply > 0 &&
				isSlotResult(gameData[data.index]) &&
				data.item.game_state === "FreeGame"'
                style='white-space:nowrap;'
            >
                {{ $t('freeGameTotalMultiplier') }} : {{ gameData[data.index].now_acc_game_multiply }}
            </p>

            <div v-if='isDiceResult(gameData[data.index])'>
                <p style='white-space:nowrap;'>
                    {{ $t('slot.get') + $t('slot.multiplier') }} : {{ getMonopolyMultiply(data) }}
                </p>
                <p style='white-space:nowrap;'>
                    {{ $t('slot.10031.dice') + $t('slot.total') + $t('slot.point') }} :
                    {{ getMonopolyDiceTotalPoint(data) }}
                </p>
                <p
                    v-if='hasRemainingProtection(data)'
                    style='white-space:nowrap;'
                    class="holdProtection"
                >
                    <!--		TODO 需請美術出免死金牌圖			-->
                    {{ $t('slot.hold') + $t('slot.10031.protection') }}
                </p>
            </div>
            <b-row
                class='m-0 p-0 border-top border-dark'
                v-if='isSlotResult(gameData[data.index])'
                v-html='showLine(gameData[data.index])'
            >
            </b-row>

            <div
                v-else
                class='mt-0 ml-0 mr-0 mb-3 p-0 border-top border-dark'
            />

            <div v-if="isDiceResult(gameData[data.index])">
                <p
                    v-if='isGetProtection(data)'
                    style='white-space:nowrap;'
                    class="getProtection"
                >
                    {{ $t('slot.get') + $t('slot.10031.protection') }}
                </p>
                <p
                    v-if='isUsedProtection(data)'
                    style='white-space:nowrap;'
                    class="usedProtection"
                >
                    {{ $t('slot.used') + $t('slot.10031.protection') }}
                </p>
                <p
                    v-if='isSpecialResult(data)'
                    style='white-space:nowrap;'
                >
                    {{ $t('slot.10031.specialResult') }} :
                </p>
                <b-row
                    class='m-0 p-0'
                    v-html='showSpecialResult(data)'
                />
            </div>

        </template>

    </b-table>
</template>

<script>

import { checkMonopolySymbolId } from '@/utils/Slot';

export default {
    name: 'SlotMonopolyTable',
    props: {
        item: { bet_point: 0 },
        gameData: null,
        gameType: { type: Number, default: null, required: true },
        currencyType: { type: String, default: null, required: true },
    },
    data() {
        return {
            diceCount: {
                10031: 2,
            },
            mapInfoList: [],
            viewRowList: [],
        };
    },
    updated() {
        this.getMapInfoList();
    },
    methods: {
        isDiceResult(gameData) {
            return gameData.dice_game_result && gameData.dice_game_result.winning_dice_result;
        },
        isSlotResult(gameData) {
            return gameData.win_info_list;
        },
        isSpecialResult(data) {
            return data.item.dice_game_result.winning_dice_result.special_game_detail;
        },
        getFields() {
            return [
                {
                    key: 'slotGUI',
                    label: '',
                    sortable: false,
                },
                {
                    key: 'detail',
                    label: this.$t('detail'),
                    tdClass: 'align-top',
                    sortable: false,
                },
            ];
        },
        getSpinTitle(item, index) {
            let title = '[ ' + item.game_state;
            if (index > 0) {
                title += '<span class="text-success ml-1">' + index + '</span>';
            }
            return title + ' ]';
        },
        getPositionResult(index) {
            let positionResult = this.gameData[index].position_result || [];
            let symbolList = [];
            let length = positionResult.length;
            for (let i = 0; i < length; i++) {
                let length1 = positionResult[i].length;
                for (let j = 0; j < length1; j++) {
                    symbolList[j] = symbolList[j] || [];
                    symbolList[j][i] = positionResult[i][j];
                }
            }
            return symbolList;
        },
        getSymbolIcon(winInfo, x, y) {
            let symbolId = winInfo.origin_frame[y][x];
            let prefix = '';
            symbolId = checkMonopolySymbolId(this.gameType, symbolId, winInfo, x, y);

            let winLines = winInfo.win_lines || [];
            let isWin = winLines.some(winLine => winLine.is_win_position[y][x]);
            if (isWin) {
                prefix = 'alerts-border';
            }

            return `icon-small slot-${this.gameType} slot-${this.gameType}-${symbolId}  ${prefix}`;
        },
        getWinLinesTotalWin(winInfo) {
            let winLines = winInfo.win_lines || [];
            if (winLines.length > 0) {
                return winInfo.win_lines
                    .map(winLine => winLine.win_point)
                    .reduce((a, b) => a + b);
            }

            return 0;
        },
        getWinLinesCascadeMultiply(winInfo) {
            let winLines = winInfo.win_lines || [];
            if (winLines.length > 0) {
                return winInfo.win_lines[0].cascade_multiply || 0;
            }

            return 0;
        },
        showLine(data) {
            let winInfo = data.win_info_list[data.page - 1];
            let htmlStr = '';
            console.log("showLine => ", data)
            if (winInfo.win_lines) {
                let length = winInfo.win_lines.length;
                for (let i = 0; i < length; i++) {
                    let winLine = winInfo.win_lines[i];
                    if (winLine.win_point > 0) {
                        htmlStr += `<div class='col col-xl-3 col-lg-4 col-md-7 col-sm-7 mb-2 mt-2'>`;
                        htmlStr += `<i class='row mb-1 icon-big slot-line-${this.gameType} slot-line-${this.gameType}-${winLine.line_no + 1}'></i>`;
                        if (winLine.line_no > -1) {
                            htmlStr += `<span class='row text-info' style='white-space:nowrap;'>LinNo : ${winLine.line_no + 1}</span>`;
                        }
                        let point = this.convertMoney(this.currencyType, winLine.win_point, true);
                        htmlStr += `<span class='row' style='white-space:nowrap;'>${point}</span>`;
                        htmlStr += '</div>';
                    }
                }
            }

            return htmlStr;
        },
        getMonopolyAwardsTitle(data) {
            let mapSymbol = data.item.dice_game_result.winning_dice_result.monopoly_map_symbol;
            return this.$t(`slot.10031.awardsTitle.${mapSymbol}`) || '';
        },
        getDiceCount() {
            return this.diceCount[this.gameType];
        },
        getMonopolyDicePoint(data, diceNo) {
            let diceResult = data.item.dice_game_result.winning_dice_result;
            let dicePoint = diceResult[`point_${diceNo}`];
            return `icon-small slot-${this.gameType} slot-${this.gameType}-dice_${dicePoint}`;
        },
        getMonopolyDiceTotalPoint(data) {
            return data.item.dice_game_result.winning_dice_result.total_point || 0;
        },
        getMonopolyCurrentRound(data) {
            return data.item.dice_game_result.winning_dice_result.current_round || 0;
        },
        getMonopolyMultiply(data) {
            return data.item.dice_game_result.winning_dice_result.multiply || 0;
        },
        isGetProtection(data) {
            return data.item.dice_game_result.winning_dice_result.is_get_protection || false;
        },
        isUsedProtection(data) {
            return data.item.dice_game_result.winning_dice_result.used_protection || false;
        },
        hasRemainingProtection(data) {
            return data.item.dice_game_result.winning_dice_result.has_remaining_protection || false;
        },
        getMapInfoList() {
            if (!this.gameData[1] || !this.gameData[1].dice_game_result) {
                return
            }
            let mapList = this.gameData[1].dice_game_result.map_list;
            let mapInfoList = [];
            for (let i = 0; i < mapList.length; i++) {
                let mapStr = mapList[i];
                let infoList = mapStr.split('-');
                this.mapInfoList.push({
                    symbolType: infoList[0],
                    multiply: Number(infoList[1]),
                    mapID: i + 1,
                });
            }

            mapInfoList = JSON.parse(JSON.stringify(this.mapInfoList));

            let totalNum = this.mapInfoList.length;
            // 單邊去掉角落的格子數量
            let avgNum = (totalNum - 4) / 4;

            // 取得各邊的長度
            let lenList = [];
            for (let i = 0; i < 4; i++) {
                if ((i + 1) % 2 === 0) {
                    lenList.push(avgNum + 2);
                } else {
                    lenList.push(avgNum);
                }
            }

            // 取得要顯示用的陣列
            let viewSymbolList = [];
            for (let i = 0; i < lenList.length; i++) {
                const symbolList = mapInfoList.splice(0, lenList[i]);
                if (i < 2) {
                    viewSymbolList.push(symbolList.reverse());
                } else {
                    viewSymbolList.push(symbolList);
                }
            }

            let viewRowList = [];
            viewRowList.push(viewSymbolList[1]);
            for (let i = 0; i < avgNum; i++) {
                let rowList = [];
                const e1 = viewSymbolList[2][i];
                const e2 = viewSymbolList[0][i];
                rowList.push(e1);
                for (let j = 0; j < avgNum; j++) {
                    rowList.push(null);
                }
                rowList.push(e2);
                viewRowList.push(rowList);
            }
            viewRowList.push(viewSymbolList[3]);
            this.viewRowList = viewRowList;
        },
        bindMapID(mapSymbol) {
            return mapSymbol ? mapSymbol.mapID : '';
        },
        showMapSymbol(mapSymbol) {
            let showText = '-';
            if (!mapSymbol) {
                return showText;
            }

            switch (mapSymbol.symbolType) {
                case 'M':
                    showText = `×${mapSymbol.multiply}`;
                    break;
                case 'X':
                    showText = this.$t('slot.10031.awardsTitle.X');
                    break;
                case 'T':
                    showText = this.$t('slot.10031.awardsTitle.T');
                    break;
                case 'S':
                    showText = this.$t('slot.10031.awardsTitle.S');
                    break;
                case 'W':
                    showText = this.$t('slot.10031.awardsTitle.W');
                    break;
            }

            if (mapSymbol.mapID === 24) {
                showText = showText + '/' + this.$t('slot.10031.start');
            }

            return showText;
        },
        mapSymbolClass(data, mapSymbol) {
            // 地圖格子css
            let symbolClass = 'mapBlock';

            // 空格css
            if (!mapSymbol) {
                symbolClass = 'spaceBlock';
                return symbolClass;
            }

            // 中獎格子css
            if (data.item.dice_game_result.winning_dice_result.position === mapSymbol.mapID) {
                symbolClass = `${symbolClass} getAwards`;
            }

            return symbolClass;
        },
        showSpecialResult(data) {
            let htmlStr = '';
            let awardsText = '';
            let winResult = data.item.dice_game_result.winning_dice_result;

            if (!winResult.special_game_detail) {
                return htmlStr;
            }

            let mapSymbol = winResult.monopoly_map_symbol;
            let spResult = winResult.special_game_detail;
            switch (mapSymbol) {
                case 'T':
                    awardsText += `×${spResult.multipliers[spResult.target_index]}`;
                    break;
                case 'S':
                    for (let i = 0; i < spResult.multipliers.length; i++) {
                        if (i === 0) {
                            awardsText += `×${spResult.multipliers[i]}`;
                        } else {
                            awardsText += ` , ×${spResult.multipliers[i]}`;
                        }
                    }
                    break;
                case 'W':
                    awardsText += `×${spResult.multipliers[spResult.target_index]}`;
                    break;
            }

            htmlStr += `<div class='col col-xl-3 col-lg-4 col-md-7 col-sm-7 mb-2 mt-2'>`;
            htmlStr += `<i class='row mb-1 icon-big slot-${this.gameType} slot-${this.gameType}-sp_${mapSymbol}'></i>`;
            htmlStr += `<span class='row text-info' style='white-space:nowrap;'>${this.$t('slot.get') + this.$t('slot.multiplier') + ' : ' + awardsText}</span>`;
            htmlStr += '</div>';

            return htmlStr;
        },
    },
};
</script>

<style scoped>
.center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.map {
    display: grid;
    /* 设置列数和每列宽 */
    grid-template-columns: repeat(7, 80px);
    /* 设置行数和每行高 */
    grid-template-rows: repeat(7, 40px);
    margin-top: 10px;
    margin-bottom: 30px;
}

.map div {
    text-align: center;
    line-height: 40px;
    color: white;
    background-color: #174c6b;
    /* text-shadow:  4px 1px 2px  aquamarine; */
}

.mapBlock {
    border: 2px white solid;
}

.spaceBlock {
    opacity: 0;
    border: 2px white solid;
}

.getAwards {
    animation: getAwards 1.5s ease-in-out infinite alternate;
}

@keyframes getAwards {
    0%,
    100% {
        text-shadow: 0 0 20px yellow, 0 0 100px yellow, 0 0 20px yellow;
    }

    50% {
        text-shadow: 0 0 50px yellow, 0 0 150px yellow, 0 0 50px yellow;
        border: 2px red solid;
        color: yellow;
    }
}

.getProtection {
    color: green;
}

.usedProtection {
    color: red;
}

.holdProtection {
    color: #0177b4;
}
</style>
